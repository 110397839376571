import { useMantineColorScheme } from "@mantine/core";
import { useHotkeys }            from "@mantine/hooks";

import "_common/translation/i18n";
import { useGetDarkMode, useSetDarkMode } from "_common/hooks/global";
import { checkIsBrowser }                 from "_common/utils";

const Shortcuts = props => {
	const { darkMode } = props;

	const darkModeCookie        = darkMode === undefined ? false : darkMode;
	const __darkMode            = !checkIsBrowser () ? darkModeCookie : useGetDarkMode ();
	const { toggleColorScheme } = useMantineColorScheme ();
	const toggleDarkMode        = useSetDarkMode ();

	useHotkeys ( [
		[ "mod+j", () => {
			toggleDarkMode ( !__darkMode );
			toggleColorScheme ();
		} ],
		[ "/", () => {
			document.getElementById ( "input-popover-target" )?.focus ();
		} ]
	] );

	return null;
};

export default Shortcuts;
